<template>
  <div class="ppdb">
    <div class="container mt-5">
      <div class="row">
        <div class="col-lg-12 mt-3">
          <div class="card">
            <div class="card-body">
              <a-steps
                size="small"
                :status="status"
                v-model="current"
                @change="handleStepChange"
              >
                <a-step
                  v-for="step in steps"
                  :key="step.title"
                  :title="steps[current].title === step.title ? step.title : null"
                  :disabled="step.disabled"
                />
              </a-steps>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header d-flex justify-content-between">
              <div>
                {{ steps[current].title }}
              </div>
              <div>
                <p class="requiredForm">: wajib</p>
              </div>
            </div>
            <div class="card-body">
              <PersonalData
                v-if="current === 0"
                :isClickAction="isClickAction"
                @actionValidate="actionValidate"
                :data="payload.pribadi"
                :lampiran="lampiran"
                @removeLampiran="removeLampiran"
                @addLampiran="addLampiran"
                :base64FotoDiri="base64FotoDiri"
              />
              <ParentData
                v-else-if="current === 1"
                :isClickAction="isClickAction"
                @delete="deleteParent"
                @actionValidate="actionValidate"
                :datas="payload.orangtua"
                :inputKontak="payload.kontak"
              />
              <form-rincian-peserta-didik
                v-if="current === 2"
                :data="payload.rincian_peserta_didik"
                :isClickAction="isClickAction"
                @actionValidate="actionValidate"
              />
              <form-registrasi-peserta-didik
                v-if="current === 3"
                :data="payload.registrasi_peserta_didik"
                :isClickAction="isClickAction"
                @actionValidate="actionValidate"
                :lampiran="lampiran"
                @removeLampiran="removeLampiran"
                @addLampiran="addLampiran"
              />
            </div>
            <div class="card-footer text-right">
              <a-button
                v-if="current > 0"
                @click="clickAction('previous')"
                type="default"
                size="large"
                class="mr-3"
                :disable="isClickAction.previous"
                >Sebelumnya</a-button
              >
              <a-button
                @click.prevent="clickAction('next')"
                :disable="isClickAction.next"
                :loading="loading"
                type="primary"
                size="large"
                >{{
                  current === steps.length - 1 ? "Kirim Data" : "Berikutnya"
                }}</a-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { disabledList, livedWithList, transportationModeList, extracurricularList, educationsList, worksList, monthlyRevenueList, alasanLayakPIPLists, jenisPrestasiList, tingkatPrestasiList, jenisBeasiswaList, jenisPendaftaranList, keluarKarenaList } from '@/helpers/listsPpdbSelect'
import moment from 'moment'
import axios from 'axios'
import config from '@/config'

function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

function dataURLtoFile(dataurl, filename) {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

const PersonalData = () => import('./PersonalData')
const ParentData = () => import('./ParentData')
const FormRincianPesertaDidik = () => import('./FormRincianPesertaDidik')
const FormRegistrasiPesertaDidik = () => import('./FormRegistrasiPesertaDidik')
const steps = [
  {
    title: 'Data Pribadi',
    status: 'process',
    disabled: true,
  },
  {
    title: 'Data Orang Tua/Wali',
    status: 'wait',
    disabled: true,
  },
  {
    title: 'Data Rincian Peserta Didik',
    status: 'wait',
    disabled: true,
  },
  {
    title: 'Registrasi Peserta Didik',
    status: 'wait',
    disabled: true,
  },
]
export default {
  props: {
    dataPpdb: {
      type: Object,
    },
  },
  components: { PersonalData, ParentData, FormRincianPesertaDidik, FormRegistrasiPesertaDidik },
  data() {
    return {
      steps,
      status: 'process',
      current: 0,
      isFinish: false,
      isClickAction: {
        next: false,
        previous: false,
      },
      loading: false,
      payload: {
        pribadi: {},
        rincian_peserta_didik: {},
        registrasi_peserta_didik: {},
        orangtua: [
          { key: 'ayah_kandung', tipe: 'ayah_kandung' },
          { key: 'ibu_kandung', tipe: 'ibu_kandung' },
          { key: 'wali', tipe: 'wali' },
        ],
        kontak: {},
      },
      lampiran: {
        ijazah: [],
        akta_lahir: [],
        rapor: [],
        kartu_keluarga: [],
        foto_diri: [],
      },
      base64FotoDiri: null,
    }
  },
  methods: {
    handleStepChange(value) {
    },
    sentData() {
      // console.log('send', this.payload)
    },
    clickAction(action) {
      this.isClickAction[action] = true
    },
    actionValidate(payload) {
      if (!payload) {
        this.status = 'error'
        this.isClickAction = { next: false, previous: false }
      } else {
        const { action } = payload
        this.isClickAction[action] = false
        if (action === 'next' || action === 'previous') {
          this.payload = {
            ...this.payload,
            ...payload.data,
          }
          if (action === 'next') {
            if (this.current === this.steps.length - 1) {
              this.$confirm({
                title: 'Warning',
                content: (
                  <div>Are you sure want to submit this form?</div>
                ),
                onOk: () => {
                  this.submitPayload()
                },
                onCancel: () => {
                },
                centered: true,
                icon: 'warning',
                okType: 'primary',
                okText: 'Submit Now',
                cancelText: 'Cancel',
              })
            } else {
              this.status = 'process'
              this.current++
            }
          } else this.current--
        // } else if (action === 'previous') this.current--
        } else if (action === 'push') {
          if ('orangtua' in payload.data) {
            for (let i = 0; i < this.payload.orangtua.length; i++) {
              const tipe = this.payload.orangtua[i].tipe
              if (tipe === payload.data.orangtua.tipe) {
                this.payload.orangtua[i] = {
                  ...this.payload.orangtua[i],
                  ...payload.data.orangtua,
                }
                break
              }
            }
          }
        }
      }
    },
    deleteParent(record) {
      this.payload.orangtua = this.payload.orangtua.filter(ortu => ortu.key !== record.key)
    },
    async submitPayload() {
      try {
        const { pribadi, orangtua, kontak } = this.payload

        // -------------MURID--------------------- //
        const { nama, nisn, jenis_kelamin: jenisKelamin, nik_kitas: nikKitas, akta_lahir: akta, anak_ke: anakKe, nomor_kks: kks, agama } = pribadi
        const { nomor_telepon_rumah: rumah, nomor_hp: hp, email, email_perwakilan_orangtua: emailOrtu } = kontak
        let murid = {
          nama,
          nisn,
          jenis_kelamin: jenisKelamin,
          nik_kitas: nikKitas,
          akta_lahir: akta,
          anak_ke: anakKe,
          nomor_kks: kks,
          nomor_hp: `0${hp}`,
          nomor_telepon: `0${rumah}`,
          email,
          agama,
          email_perwakilan_orangtua: emailOrtu,
        }

        // console.log('1. Murid', murid)

        // tempat tanggal lahir
        murid.tempat_tanggal_lahir = {
          tempat: pribadi.tempat_lahir,
          tanggal: moment(pribadi.tanggal_lahir, 'YYYY-MM-DD').format('DD-MM-YYYY'),
        }

        // console.log('3. Murid TTL', murid)

        // kewarganegaraan
        const { kewarganegaraan, nama_negara: negara } = pribadi
        murid.kewarganegaraan = kewarganegaraan

        if (kewarganegaraan === 'WNA') murid.nama_negara = negara

        // kebutuhan khusus murid
        if (pribadi.isBerkebutuhanKhusus === 'ya') {
          const kebutuhanKhususMurid = pribadi.berkebutuhan_khusus.map(data => {
            const findData = disabledList.find(list => list.name === data)
            const disabledObj = findData ? {
              key: findData.key,
              kode: findData.kode_huruf,
              deskripsi: data,
            } : {}
            return {
              ...disabledObj,
            }
          })
          murid.berkebutuhan_khusus = kebutuhanKhususMurid
        }

        // console.log('4. Murid Kebutuhan Khusus', murid)

        // alamat
        const { alamat: jalan, rt, rw, nama_dusun: dusun, kecamatan, provinsi, kota } = pribadi
        const beautifyData = {
          kecamatan: kecamatan?.split('_')[1],
          provinsi: provinsi?.split('_')[1],
          kota: kota?.split('_')[1],
        }
        murid.alamat_lengkap = {
          ...beautifyData,
          jalan,
          rt,
          rw,
          dusun,
          kelurahan_desa: pribadi.nama_kelurahan,
          kode_pos: pribadi.kode_pos,
        }

        // console.log('5. Murid Alamat Lengkap', murid)

        // tempat_tinggal
        const { tempat_tinggal: tempatTinggal } = pribadi
        const tempatTinggalMurid = livedWithList.find(list => list.name === tempatTinggal)
        murid.tempat_tinggal = {
          kode: tempatTinggalMurid?.key,
          deskripsi: tempatTinggal,
        }

        // console.log('6. Murid Tempat Tinggal', murid)

        // moda_transportasi
        const { moda_transportasi: modaTrans } = pribadi
        const transData = transportationModeList.find(list => list.name === modaTrans)
        murid.moda_transportasi = {
          kode: transData?.key,
          deskripsi: modaTrans,
        }

        // console.log('7. Murid Moda Transportasi', murid)

        // jenis_ekstrakurikuler
        const { jenis_ekstrakurikuler: ekskul } = kontak
        if (ekskul && ekskul.length) {
          const jenisEkstrakurikulerMurid = ekskul.map(data => {
            const findData = extracurricularList.find(list => list.name === data)
            const exculObj = {
              key: findData?.key,
              kode: findData?.kode,
              nama: data,
            }
            return {
              ...exculObj,
            }
          })
          murid.jenis_ekstrakurikuler = jenisEkstrakurikulerMurid
        }

        // console.log('8. Murid Esksul', murid)

        // kip dan pip
        if (pribadi.kip === 'ya') {
          murid.kip = {
            nomor: pribadi.nomor_kip,
            nama: pribadi.nama_kip,
            isTerimaFisikKIP: pribadi.isTerimaFisikKIP === 'ya',
          }
          // console.log('9. Murid KIP', murid)
        } else {
          if (pribadi.layak_pip === 'ya') {
            const findData = alasanLayakPIPLists.find(list => list.name === pribadi.alasan_pip)
            murid.pip = findData ? {
              kode: findData.key,
              alasan: pribadi.alasan_pip,
            } : null

            // console.log('10. Murid PIP', murid)
          }
        }

        // kps_pkh
        if (pribadi.kps_pkh === 'ya') {
          murid.kps_pkh = pribadi.nomor_kps_pkh
          // console.log('11. Murid KPS PKH', murid)
        }

        // ---------------ORANGTUA-------------------- //
        // kebutuhan khusus orangtua
        const dataOrangtua = []
        orangtua.forEach(ortu => {
          if ('nama' in ortu) {
            const { nama, nik, tahun_lahir: tahunLahir, tipe } = ortu
            const newData = {
              nama,
              nik,
              tahun_lahir: tahunLahir,
              tipe,
            }

            // kebutuhan khusus ortu
            if (ortu.isBerkebutuhanKhusus === 'ya') {
              const kebutuhanKhususOrtu = []
              ortu.berkebutuhan_khusus.forEach(keb => {
                const findData = disabledList.find(list => list.name === keb)
                const disabledObj = findData ? {
                  key: findData.key,
                  kode_huruf: findData.kode_huruf,
                  deskripsi: keb,
                } : null
                if (disabledObj) kebutuhanKhususOrtu.push(disabledObj)
              })
              if (kebutuhanKhususOrtu.length) {
                newData.berkebutuhan_khusus = kebutuhanKhususOrtu
              }
            }

            // pendidikan terakhir ortu
            const dataEducation = educationsList.find(edu => edu.name === ortu.pendidikan_terakhir)
            if (dataEducation) {
              newData.pendidikan_terakhir = {
                deskripsi: ortu.pendidikan_terakhir,
                kode_angka: dataEducation.key,
              }
            }

            // pekerjaan
            const dataPekerjaan = worksList.find(work => work.name === ortu.pekerjaan)
            if (dataPekerjaan) {
              newData.pekerjaan = {
                deskripsi: ortu.pekerjaan,
                kode_angka: dataPekerjaan.key,
              }
            }

            // penghasilan bulanan
            const dataPenghasilan = monthlyRevenueList.find(revenue => revenue.name === ortu.penghasilan_bulanan)
            if (dataPenghasilan) {
              newData.penghasilan_bulanan = {
                deskripsi: ortu.penghasilan_bulanan,
                kode_angka: dataPenghasilan.key,
              }
            }
            dataOrangtua.push(newData)
          }
        })

        // console.log('1. Orang Tua', dataOrangtua)

        // RINCIAN PESERTA DIDIK DAN REGISTRASI
        const { rincian_peserta_didik: rincianPesertaDidik, registrasi_peserta_didik: registrasiPesertaDidik } = this.payload

        const geolokasi = {
          latitude: rincianPesertaDidik.location.position.lat,
          longitude: rincianPesertaDidik.location.position.lng,
          alamat: rincianPesertaDidik.location.address,
          jarak: rincianPesertaDidik.jarak_tempat_tinggal_ke_sekolah,
          status: rincianPesertaDidik.jarak_tempat_tinggal_ke_sekolah < 1 ? 'kurang' : 'lebih' + ' dari 1km',
        }

        const provinsiAsalSekolah = registrasiPesertaDidik.provinsi_asal_sekolah?.split('_')[1]

        const asalSekolah = {
          nama: registrasiPesertaDidik.asal_sekolah,
          jenjang: registrasiPesertaDidik.jenjang_asal_sekolah,
          status: registrasiPesertaDidik.status_asal_sekolah,
          kota: registrasiPesertaDidik.kota_asal_sekolah,
          provinsi: provinsiAsalSekolah,
        }

        const findDataJenisPendaftaran = jenisPendaftaranList.find(list => list.title === registrasiPesertaDidik.jenis_pendaftaran)
        const findDataKeluarKarena = keluarKarenaList.find(list => list.title === registrasiPesertaDidik.keluar_karena)

        const newJenisPendaftaranObj = findDataJenisPendaftaran ? {
          kode_angka: findDataJenisPendaftaran.key,
          deskripsi: findDataJenisPendaftaran.title,
        } : null
        const newKeluarKarenaObj = findDataKeluarKarena ? {
          kode_angka: findDataKeluarKarena.key,
          deskripsi: findDataKeluarKarena.title,
        } : null

        const newDataMuridFromRincianAndRegistrasi = {
          ...rincianPesertaDidik,
          ...registrasiPesertaDidik,
          asal_sekolah: asalSekolah,
          geolokasi_tempat_tinggal: geolokasi,
          jenis_pendaftaran: newJenisPendaftaranObj,
          keluar_karena: newKeluarKarenaObj,
        }

        murid = {
          ...murid,
          ...newDataMuridFromRincianAndRegistrasi,
        }

        // console.log('12. Murid Rincian & Registrasi', murid)

        // PRESTASI & BEASISWA
        const { data_prestasi: dataPrestasi, data_beasiswa: dataBeasiswa } = rincianPesertaDidik

        let prestasi = []
        if (dataPrestasi.length) {
          prestasi = dataPrestasi.map(row => {
            const findDataJenis = jenisPrestasiList.find(list => list.title === row.jenis)
            const findDataTingkat = tingkatPrestasiList.find(list => list.title === row.tingkat)

            const newJenisObj = findDataJenis ? {
              kode_angka_jenis: findDataJenis.key,
              jenis: findDataJenis.title,
            } : null
            const newTingkatObj = findDataTingkat ? {
              kode_angka_tingkat: findDataTingkat.key,
              tingkat: findDataTingkat.title,
            } : null

            return {
              ...row,
              ...newJenisObj,
              ...newTingkatObj,
            }
          })
        }

        // console.log('3. Prestasi', prestasi)

        let beasiswa = []
        if (dataBeasiswa.length) {
          beasiswa = dataBeasiswa.map(row => {
            const findDataJenis = jenisBeasiswaList.find(list => list.title === row.jenis)

            const newJenisObj = findDataJenis ? {
              kode_angka_jenis: findDataJenis.key,
              jenis: findDataJenis.title,
            } : null

            return {
              ...row,
              ...newJenisObj,
            }
          })
        }

        // console.log('4. Beasiswa', beasiswa)

        // console.log('RESULT SUBMIT')
        // console.log(murid, dataOrangtua, prestasi, beasiswa)

        // undone obj = kip, pip, pkh, kps
        // console.log(murid, dataOrangtua, 'dataa')
        this.loading = true
        const uuid = await this.$store.dispatch('ppdb/PUT_PPDB', { murid, orangtua: dataOrangtua, prestasi, beasiswa })
        if (uuid) {
          if (this.lampiran.ijazah[0] || this.lampiran.rapor[0] || this.lampiran.akta_lahir[0] || this.lampiran.kartu_keluarga[0]) {
            const formData = new FormData()
            for (const key in this.lampiran) {
              if (this.lampiran[key][0]) {
                formData.append(key, this.lampiran[key][0])
              }
            }
            formData.append('uuid', uuid)
            await axios.post(`${config.apiUrl}/api/file/file-ppdb`, formData, {
              headers: {
                token: localStorage.token,
                'Access-Control-Allow-Origin': '*',
              },
            })
          }
        }
        this.$notification.success({
          message: 'SUCCESS UPDATED PPDB',
          description: 'Thank you for fulfilling PPDB.',
        })
        this.loading = false
        this.isFinish = true
        this.$store.commit('ppdb/SET_STATE', {
          ppdbIsFulfilled: true,
        })
        this.$emit('fetch-ppdb')
        window.removeEventListener('beforeunload', null)
        window.removeEventListener('unload', null)
      } catch (err) {
        console.log(err)
        window.removeEventListener('beforeunload', null)
        window.removeEventListener('unload', null)
        this.$notification.error({
          message: 'FAILED UPDATING PPDB',
          description: 'Sorry for the trouble. Please contact school for this error.',
        })
      }
    },
    addLampiran({ file, type }) {
      if (type === 'foto_diri') {
        if (file.type) {
          getBase64(file, imageUrl => {
            this.base64FotoDiri = imageUrl
          })
          this.lampiran[type] = [file]
        } else {
          this.base64FotoDiri = file
          const newFile = dataURLtoFile(file, 'image.jpg')
          this.lampiran[type] = [newFile]
        }
      } else {
        this.lampiran[type] = [file]
      }
      this.$notification.success({
        message: 'SUKSES MENGUNGGAH',
        description: 'Sukses mengunggah berkas/dokumen. Silahkan melanjutkan pengisian.',
      })
      // const isPdf = file.type === 'application/pdf'
      // if (!isPdf) {
      //   this.$notification.error({
      //     message: 'GAGAL UNGGAH',
      //     description: 'Mohon hanya mengunggah dokumen berekstensi pdf.',
      //   })
      // } else if (file.size > 8388608) {
      //   this.$notification.error({
      //     message: 'GAGAL UNGGAH',
      //     description: 'Mohon hanya mengunggah file kurang dari 8mb.',
      //   })
      // } else {
      //   this.lampiran[type] = [file]
      // }
      // console.log(this.attachment.file)
      return false
    },
    removeLampiran({ file, type }) {
      this.lampiran[type] = []
    },
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', null)
    window.removeEventListener('unload', null)
  },
  created() {
    const { nama, jenis_kelamin: jenisKelamin, nisn, agama, nik_kitas: nik, tempat_tanggal_lahir: ttl, akta_lahir: akta, orangtuas, nomor_telepon: noTelp, nomor_hp: hp, email, email_perwakilan_orangtua: emailOrtu, nis, ijazah, nomor_seri_ijazah: noIjazah } = this.dataPpdb
    const switchObjParent = {
      ayah: 'ayah_kandung',
      ibu: 'ibu_kandung',
      wali: 'wali',
    }

    const orangtua = []

    for (let i = 0; i < this.payload.orangtua.length; i++) {
      const payloadParent = this.payload.orangtua[i]
      const dataPpdbParent = orangtuas?.find(ortu => payloadParent.tipe === switchObjParent[ortu.tipe])
      if (dataPpdbParent) {
        orangtua.push({
          ...dataPpdbParent,
          ...payloadParent,
        })
      } else orangtua.push(payloadParent)
    }

    this.payload = {
      ...this.payload,
      pribadi: {
        ...this.payload.pribadi,
        nama,
        jenis_kelamin: jenisKelamin,
        nisn,
        agama,
        nik,
        tempat_lahir: ttl && ttl.tempat ? ttl.tempat : null,
        tanggal_lahir: ttl && ttl.tanggal ? moment(ttl.tanggal, 'DD-MM-YYYY') : ttl.tanggal_lahir ? moment(ttl.tanggal_lahir, 'DD-MM-YYYY') : null,
        akta_lahir: akta,
      },
      rincian_peserta_didik: {},
      registrasi_peserta_didik: {
        nis,
        nomor_seri_ijazah: noIjazah || ijazah,

      },
      orangtua,
      kontak: {
        ...this.payload.kontak,
        nomor_telepon_rumah: noTelp,
        nomor_hp: hp,
        email,
        email_perwakilan_orangtua: emailOrtu,
      },
    }
  },
  mounted() {
    if (this.ppdbIsFulfilled) {
      window.removeEventListener('beforeunload', null)
      window.removeEventListener('unload', null)
    } else {
      window.addEventListener('beforeunload', function(event) {
        event.returnValue = 'Are you sure to leave this page? You have undone form here.'
      })
    }
  },
  computed: {
    ppdbIsFulfilled() {
      return this.$store.state.ppdb.ppdbIsFulfilled
    },
  },
  watch: {
    ppdbIsFulfilled(newVal) {
      if (newVal) {
        window.removeEventListener('beforeunload', null)
        window.removeEventListener('unload', null)
      }
    },
  },
}
</script>

<style lang="scss">
.ppdb {
  .requiredForm::before {
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }
}
</style>
